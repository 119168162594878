// core
import ls from 'local-storage'
import _ from 'lodash'
import RichTextEditor from 'react-rte'

// actions
import ProfileActions from '../../../core/actions/users/profileactions'

// helpers
import Helper from '../../../core/helpers/platformhelper'

// initial states
const formFields = {
  first_name: '',
  last_name: '',
  user_email_address: '',
  user_phone: '',
  user_mobile: '',
  default_store_id: '',
  store_groups: [],
  crm_email: '',
  emailMessageIsCustom: false,
  send_message: RichTextEditor.createEmptyValue(),
  emailSignatureIsCustom: false,
  signature: RichTextEditor.createEmptyValue(),
  send_mobile_alerts: false,
  send_email_alerts: false,
  view_frequency: 'all',
  enable_privacy_mobile_number: false,
  enable_zoom_request_email_notification: false,
  enable_zoom_request_sms_notification: false,
  enable_zoom_request_push_notification: false,
  cvd_mode: false,
  dark_mode_webapp: false,
  vdp_store_follow_user: false,
}
const initialAccountSettingsState = {
  userProfile: { ...formFields },
  initialValues: { ...formFields },
}

const initialErrors = {
  first_name: '',
  last_name: '',
  user_email_address: '',
  user_phone: '',
  user_mobile: '',
  crm_email: '',
  send_message: '',
  signature: '',
}

const initialModifiedFields = {
  profile: {},
  privacyPreferences: {},
}

// data formatting & manipulation
const formatPhoneNumber = phone_number => {
  if (phone_number) {
    let match = phone_number.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
  }
  return null
}

const matchIdToStoreName = (default_store_id, arrOfStores) => {
  return arrOfStores.find(x => x.company_store_id === default_store_id)
}

const validate = (field, value) => {
  switch (field) {
    case 'first_name':
      if (!value) {
        return 'Required'
      } else if (value.length > 15) {
        return 'Must be 15 characters or less'
      } else if (!/^[A-Za-z]+$/.test(value)) {
        return 'Must only contain letters'
      } else {
        return ''
      }
    case 'last_name':
      if (!value) {
        return 'Required'
      } else if (value.length > 20) {
        return 'Must be 20 characters or less'
      } else if (!/^[A-Za-z]+$/.test(value)) {
        return 'Must only contain letters'
      } else {
        return ''
      }
    case 'user_phone':
      if (value && value.length !== 10) {
        return 'Invalid main phone number'
      } else {
        return ''
      }
    case 'user_mobile':
      if (value && value.length !== 10) {
        return 'Invalid mobile phone number'
      } else {
        return ''
      }
    case 'user_email_address':
      if (!value) {
        return 'Required'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid email address'
      } else {
        return ''
      }
    case 'crm_email':
      if (!value) {
        return ''
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid email address'
      } else {
        return ''
      }
    case 'send_message':
      if (!value) {
        return 'Required'
      } else {
        return ''
      }
    case 'signature':
      if (!value) {
        return 'Required'
      } else {
        return ''
      }
    default:
      return ''
  }
}

const removeHTMLTags = string => {
  return string.replace(/<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/g, '')
}

const removeSpeacialCharacters = string => {
  return string.replace(/[^A-z\s\d][\\\^]?/g, '')
}

const isEmptyObject = object => {
  return JSON.stringify(object) === '{}'
}

const isEmptyString = string => {
  return string === ''
}

const areEmptyValues = object => {
  return Object.keys(object).filter(key => object[key].length > 0).length > 0
}

const parseStoreTypes = typeId => {
  switch (typeId) {
    case 1:
      return '(New)'
    case 2:
      return '(Pre-Owned)'
    case 3:
      return '(Museum)'
    case 6:
      return '(Wholesale)'
    default:
      return ''
  }
}

const modifiedValueHandler = (id, value) => {
  if (['send_message', 'signature'].includes(id)) return removeHTMLTags(value.toString('html'))
  return value
}

const savedRTEValue = (id, value) => {
  if (['send_message', 'signature'].includes(id)) return value.toString('html')
  return value
}

// state management & error handling
const handleDataFetch = (fetchedValues, currentState) => {
  return {
    ...currentState,
    ['userProfile']: {
      ...currentState['userProfile'],
      ...fetchedValues,
    },
    ['initialValues']: {
      ...currentState['initialValues'],
      ...fetchedValues,
    },
  }
}

const handleAccountSettingsUpdate = (id, value, currentState) => {
  return {
    ...currentState,
    ['userProfile']: {
      ...currentState['userProfile'],
      [id]: value,
    },
  }
}

const handleAccountSettingsFormErrors = (id, value, currentState) => {
  if (id === ('user_phone' || 'user_mobile'))
    return {
      ...currentState,
      [id]: validate(id, removeSpeacialCharacters(value)),
    }
  if (id === ('send_message' || 'signature'))
    return {
      ...currentState,
      [id]: validate(id, removeHTMLTags(value.toString('html'))),
    }
  if (id === 'emailMessageIsCustom')
    return {
      ...currentState,
      ['send_message']: '',
    }
  if (id === 'emailSignatureIsCustom')
    return {
      ...currentState,
      ['signature']: '',
    }
  if (id in currentState)
    return {
      ...currentState,
      [id]: validate(id, value),
    }
  return currentState
}

const handleModifiedValues = (id, group, value, initialValues, currentState) => {
  let initialValue = initialValues[id]
  if (id === ('user_phone' || 'user_mobile'))
    initialValue = removeSpeacialCharacters(initialValues[id]).replace(' ', '')
  if (id === ('send_message' || 'signature')) initialValue = initialValues[id].toString('html')

  if (modifiedValueHandler(id, value) !== modifiedValueHandler(id, initialValue))
    return {
      ...currentState,
      [group]: {
        ...currentState[group],
        [id]: savedRTEValue(id, value),
      },
    }
  if (modifiedValueHandler(id, value) === modifiedValueHandler(id, initialValue)) {
    if (id in currentState[group]) {
      let updatedState = { ...currentState }
      delete updatedState[group][id]
      return updatedState
    }
  }
  return currentState
}

const handleTabSectionEditIcon = (header, modifiedValues) => {
  switch (header) {
    case 'Alerts':
      return (
        Object.hasOwn(modifiedValues.profile, 'send_email_alerts') ||
        Object.hasOwn(modifiedValues.profile, 'send_mobile_alerts') ||
        Object.hasOwn(modifiedValues.profile, 'view_frequency')
      )
    case 'Packet Messages':
      return (
        Object.hasOwn(modifiedValues.profile, 'emailMessageIsCustom') ||
        Object.hasOwn(modifiedValues.profile, 'emailSignatureIsCustom') ||
        Object.hasOwn(modifiedValues.profile, 'send_message') ||
        Object.hasOwn(modifiedValues.profile, 'signature')
      )
    case 'Privacy':
      return Object.hasOwn(modifiedValues.privacyPreferences, 'enable_privacy_mobile_number')
    case 'Zoom':
      return (
        Object.hasOwn(
          modifiedValues.privacyPreferences,
          'enable_zoom_request_email_notification',
        ) ||
        Object.hasOwn(modifiedValues.privacyPreferences, 'enable_zoom_request_sms_notification') ||
        Object.hasOwn(modifiedValues.privacyPreferences, 'enable_zoom_request_push_notification')
      )
    case 'Accessibility':
      return (
        Object.hasOwn(modifiedValues.privacyPreferences, 'cvd_mode') ||
        Object.hasOwn(modifiedValues.privacyPreferences, 'dark_mode_webapp')
      )
    case 'Packet Send':
      return Object.hasOwn(modifiedValues.privacyPreferences, 'vdp_store_follow_user')
    default:
      return false
  }
}

// data fetching & handling
const updateUserWrapper = newUserData => {
  const storedUser = ls.get('userInfo')
  const updatedUser = _.assign({}, storedUser, newUserData)
  ls.set('userInfo', updatedUser) // WRITEuserInfo
}

const fetchProfile = async (user_id, token) => {
  const fetchedProfile = await ProfileActions.getProfile(user_id, token)
  if ('first_name' in fetchedProfile)
    return {
      first_name: fetchedProfile.first_name,
      last_name: fetchedProfile.last_name,
      user_email_address: fetchedProfile.user_email_address,
      user_phone:
        fetchedProfile.user_phone && fetchedProfile.user_phone.length
          ? formatPhoneNumber(removeSpeacialCharacters(fetchedProfile.user_phone))
          : '',
      user_mobile:
        fetchedProfile.user_mobile && fetchedProfile.user_mobile.length
          ? formatPhoneNumber(removeSpeacialCharacters(fetchedProfile.user_mobile))
          : '',
      default_store_id: fetchedProfile.default_store_id,
      store_groups: Helper.alphabetizeStoreList(fetchedProfile.store_groups),
      crm_email: fetchedProfile.crm_email,
      emailMessageIsCustom: fetchedProfile.send_message !== null ? true : false,
      send_message: fetchedProfile.send_message
        ? RichTextEditor.createValueFromString(fetchedProfile.send_message, 'html')
        : RichTextEditor.createEmptyValue(),
      emailSignatureIsCustom: fetchedProfile.signature !== null ? true : false,
      signature: fetchedProfile.signature
        ? RichTextEditor.createValueFromString(fetchedProfile.signature, 'html')
        : RichTextEditor.createEmptyValue(),
      send_mobile_alerts: fetchedProfile.send_mobile_alerts,
      send_email_alerts: fetchedProfile.send_email_alerts,
      view_frequency:
        fetchedProfile.view_frequency === '' || fetchedProfile.view_frequency === 'all'
          ? 'all'
          : 'first',
    }
  return {}
}

const fetchPrivacyPreferences = async (token, pref_id) => {
  const fetchedPrivacyPreferences = await ProfileActions.getPrivacyPreferences(token, pref_id)
  if ('cvd_mode' in fetchedPrivacyPreferences) {
    fetchedPrivacyPreferences.enable_privacy_mobile_number =
      fetchedPrivacyPreferences.enable_privacy_mobile_number > 0
    fetchedPrivacyPreferences.cvd_mode = fetchedPrivacyPreferences.cvd_mode > 0

    Object.keys(fetchedPrivacyPreferences).map(key => {
      if (!(key in initialAccountSettingsState.userProfile)) {
        delete fetchedPrivacyPreferences[key]
      }
    })
    return fetchedPrivacyPreferences
  }
  return {}
}

const saveProfile = async (user_id, token, modifiedProfile, currentProfile) => {
  if (!currentProfile['emailMessageIsCustom']) modifiedProfile['send_message'] = null
  if (!currentProfile['emailSignatureIsCustom']) modifiedProfile['signature'] = null

  let savedProfileResponse = {}

  if (!isEmptyObject(modifiedProfile)) {
    try {
      savedProfileResponse = await ProfileActions.legacyUpdateProfile(
        user_id,
        modifiedProfile,
        token,
      )
    } catch (error) {
      console.log(error)
      savedProfileResponse['error'] = error
    }
  }
  return savedProfileResponse
}

const savePrivacy = async (token, pref_id, privacyPreferences) => {
  let savedPrivacyResponse = {}

  if (!isEmptyObject(privacyPreferences)) {
    Object.keys(privacyPreferences).map(key => {
      if (['enable_privacy_mobile_number', 'cvd_mode'].includes(key))
        privacyPreferences[key] = privacyPreferences[key] ? 1 : 0
    })
    try {
      savedPrivacyResponse = await ProfileActions.putUserPreferences(
        token,
        pref_id,
        privacyPreferences,
      )
    } catch (error) {
      savedPrivacyResponse['error'] = error
    }
  }
  return savedPrivacyResponse
}

//exports
export {
  initialAccountSettingsState,
  initialErrors,
  initialModifiedFields,
  handleDataFetch,
  handleAccountSettingsUpdate,
  handleAccountSettingsFormErrors,
  handleModifiedValues,
  handleTabSectionEditIcon,
  updateUserWrapper,
  parseStoreTypes,
  fetchProfile,
  fetchPrivacyPreferences,
  isEmptyObject,
  areEmptyValues,
  saveProfile,
  savePrivacy,
  formatPhoneNumber,
  removeHTMLTags,
}
