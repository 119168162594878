// general: action file for all things 'profile' data being passed back and forth.

// API(s)
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'

// PROFILE - FUNCTIONS REFERENCED IN THE 'EXPORT'
// official get profile
const GetProfileConst = async (userId: number, token: string) => {
  // objects
  const profileObjData = {}

  // legacy user detail -> 0

  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  await AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoLegacyUserGetAPIURL}${userId}`, {
    method: 'GET',
    headers,
  })
    .then(response => {
      return response.json()
    })
    .then(legacyUserJson => {
      if (legacyUserJson) {
        profileObjData.legacy_user_id = legacyUserJson.legacy_user_id // legacy_user_id
        profileObjData.default_store_id = legacyUserJson.default_store_id // default_store_id
        profileObjData.username = legacyUserJson.username // username
        profileObjData.user_email_address = legacyUserJson.user_email_address // user_email_address
        profileObjData.crm_email = legacyUserJson.crm_email // crm_email
        profileObjData.enable_alerts = legacyUserJson.enable_alerts // enable_alerts
        profileObjData.send_mobile_alerts = legacyUserJson.send_mobile_alerts // send_mobile_alerts
        profileObjData.send_email_alerts = legacyUserJson.send_email_alerts
        profileObjData.view_frequency = legacyUserJson.view_frequency // view_frequency
        profileObjData.first_name = legacyUserJson.first_name // first_name
        profileObjData.last_name = legacyUserJson.last_name // last_name
        profileObjData.user_mobile = legacyUserJson.user_mobile // user_mobile
        profileObjData.user_phone = legacyUserJson.user_phone // user_phone
        profileObjData.title = legacyUserJson.title // title
        profileObjData.signature = legacyUserJson.signature // signature
        profileObjData.send_message = legacyUserJson.send_message // message
      }
    })

  // profile image -> 1
  if (
    profileObjData.username !== null &&
    profileObjData.username !== undefined &&
    profileObjData.username !== ''
  ) {
    await AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileImageGetAPIURL}${profileObjData.username}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        // log response
        return response.blob()
      })
      .then(blobResponse => {
        const base64data = {}

        // eslint-disable-next-line no-undef
        const fileReaderInstance = new FileReader()

        fileReaderInstance.readAsDataURL(blobResponse)
        fileReaderInstance.onload = () => {
          const base64 = fileReaderInstance.result
          // if/else accounts for oddly encoded default img response
          if (base64.includes('application/json')) {
            base64data.file = JSON.parse(atob(base64.split(',')[1])).default_url
          } else if (base64.includes('text/plain')) {
            throw 'API error from profile-image'
          } else {
            base64data.file = base64
          }
        }
        return base64data
      })
      .then(avatarProfileJson => {
        profileObjData.avatar = avatarProfileJson // avatar
      })
  }

  // profile store list -> 3
  await AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileStoreListGetAPIURL}${userId}`, {
    method: 'GET',
    headers,
  })
    .then(response => {
      return response.json()
    })
    .then(storeListProfileJson => {
      profileObjData.store_groups = storeListProfileJson // store_groups
    })

  // legacy user store_full_name -> 4
  if (profileObjData.default_store_id !== null && profileObjData.default_store_id !== undefined) {
    await AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoProfileStoreSingleGetAPIURL}${profileObjData.default_store_id}`,
      {
        method: 'GET',
        headers,
      },
    )
      .then(response => {
        return response.json()
      })
      .then(storeFullNameProfileJson => {
        profileObjData.store_full_name = `${storeFullNameProfileJson.store_full_name} - ${
          storeFullNameProfileJson.store_type_id === 1 ? 'New' : 'Pre-Owned'
        }` // store_full_name
      })
      .catch(error => {
        console.error(error)
      })
  }

  return profileObjData
}

// ----------EXPORT----------

export default {
  putUserPreferences: (token, prefId, params) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoNotifPrefAPIURL}${prefId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  getPrivacyPreferences: (token, prefId) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoNotifPrefAPIURL}${prefId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  // PROFILE - API - MAIN
  getProfile: (userId: number, token: string) => {
    return GetProfileConst(userId, token)
  },

  getProfileAvatar: (username: string, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileImageGetAPIURL}${username}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        // log response
        return response.blob()
      })
      .then(blobResponse => {
        const base64data = {}

        // eslint-disable-next-line no-undef
        const fileReaderInstance = new FileReader()

        fileReaderInstance.readAsDataURL(blobResponse)
        fileReaderInstance.onload = () => {
          const base64 = fileReaderInstance.result
          // if/else accounts for oddly encoded default img response
          if (base64.includes('application/json')) {
            base64data.file = JSON.parse(atob(base64.split(',')[1])).default_url
          } else if (base64.includes('text/plain')) {
            throw 'API error from profile-image'
          } else {
            base64data.file = base64
          }
        }

        return base64data
      })
      .catch(error => {
        console.error(error)
      })
  },

  putUpdatePhotoProfile: (
    avatar: string,
    avatarName: string,
    username: string,
    imageType: string,
    token: string,
  ) => {
    const params = new FormData() // eslint-disable-line no-undef
    params.append('file', avatar)
    params.append('name', avatarName)

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileImageSaveAPIURL}${username}`, {
      method: 'POST',
      headers,
      body: params,
    }).then(response => {
      return response.json()
    })
  },

  // PROFILE - API - STORE GROUPS
  getUserDefaultStoreInfo: (defaultStoreId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileStoreSingleGetAPIURL}${defaultStoreId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getUserStoreGroups: (userId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileStoreListGetAPIURL}${userId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  putUpdateDefaultStoreProfile: (userId: number, storeId: number, token: string) => {
    const params = { default_store_id: storeId }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoLegacyUserGetAPIURL}${userId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  //
  // PROFILE - API - ROLES
  getUserRoleList: (token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileRolesGetAPIURL}?items_per_page=15`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getSingleUserRole: (id: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileRoleGetAPIURL}${id}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  putSingleUserRole: (userId: number, roleId: number, token: string) => {
    // v1/users/roles/:legacy_user_id
    const params = { role_id: roleId }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileRoleGetAPIURL}${userId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  // PROFILE - API - FILE EXPORT
  // Function for submission of the Account Settings modal form
  legacyUpdateProfile: (userId: number, formDataObject: Object, token: string) => {
    const params = formDataObject
    console.log('formDataObject', formDataObject)

    // add custom send_message to params
    if (formDataObject.emailMessageIsCustom && formDataObject.send_message !== '') {
      params.send_message = formDataObject.send_message
    }

    // add custom signature to params
    if (formDataObject.emailSignatureIsCustom && formDataObject.signature !== '') {
      params.signature = formDataObject.signature
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoLegacyUserUpdateAPIURL}${userId}?html_flag=true`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
}
