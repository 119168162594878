import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import format from 'date-fns/format'
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import {
  DASHBOARD_CUSTOMER_MODAL,
  DASHBOARD_VIEWS_MODAL,
  WEBLINK_DISPLAY_MODAL,
} from '../../../core/_constants/modalTypes'
import CovideoIcon from '../../../core/assets/images/icons/covideo-icon'
import HeatmapIcon from '../../../core/assets/images/icons/heatmap_icon'
import Helper from '../../../core/helpers/platformhelper'
import SoldIcon from '../../../core/assets/images/icons/SoldIcon'

// eslint-disable-next-line react/prefer-stateless-function
class DashboardRow extends Component {
  openPacketTypeModal(info, share_uuid) {
    const { token } = this.props
    const label = info.packet_type_label
    // console.log(label)
    if (
      label === 'webapp-text-message' ||
      label === 'webapp-email' ||
      label === 'packet-share-mms-app' ||
      label === 'packet-share-mms' ||
      label === 'mobile-app-email' ||
      label === 'packet-share-email-app' ||
      label === 'mobile-app-text-message' ||
      label === 'packet-share-mms-app'
    ) {
      return this.props.showModalConnect(DASHBOARD_CUSTOMER_MODAL, {
        id_object: {
          main_id: info.customer_id,
          other_id: info.packet_history_id,
          share_id: info.packet_share_id,
        },
        token,
        content: 'packetMessage',
        teamMember: info.team_member,
        covideoId: info.covideo_video_id,
      })
    }
    if (label === 'packet-share-link' || label === 'packet-link-only') {
      // const { share_uuid } = this.props
      return this.props.showModalConnect(WEBLINK_DISPLAY_MODAL, {
        share_uuids: [share_uuid],
      })
    }
  }

  render() {
    const { info, animationDelay, token, hasInsights, share_uuid } = this.props
    let packetTypeLabel = 'Email (Portal)'
    // use packetTypeSource to later show icons
    let packetTypeSource = 'web'

    // determine packet send type to display (move later)
    if (info && info.packet_type_label) {
      let packetLabel = info.packet_type_label
      if (packetLabel.includes('mobile')) {
        packetTypeSource = 'mobile'
      }
      if (
        packetLabel === 'packet-share-mms-app' ||
        packetLabel === 'packet-share-mms' ||
        packetLabel === 'webapp-text-message'
      ) {
        packetTypeLabel = 'Text (Portal)'
      } else if (packetLabel === 'packet-share-link' || packetLabel === 'packet-link-only') {
        packetTypeLabel = 'Weblink'
      } else if (
        packetLabel === 'packet-share-price-change' ||
        packetLabel === 'price-change-alert'
      ) {
        packetTypeLabel = 'Price Change'
      } else if (packetLabel === 'packet-share-email-app' || packetLabel === 'mobile-app-email') {
        packetTypeLabel = 'Email (App)'
      } else if (
        packetLabel === 'packet-share-mms-app' ||
        packetLabel === 'mobile-app-text-message'
      ) {
        packetTypeLabel = 'Text (App)'
      } else if (packetLabel === 'packet-share-3pa' || packetLabel === 'crm-autogen-link') {
        packetTypeLabel = 'CRM'
      }
    }
    return (
      <div
        className="dashboard-table-row"
        style={{ animation: `slideInUp 250ms ${animationDelay ? animationDelay : 0}ms` }}
      >
        <div
          className="dashboard-table-row-cell dashboard-table-cell-default stick-left"
          key="team_member"
        >
          {info.team_member}
        </div>
        <div
          className="dashboard-table-row-cell dashboard-table-cell-default item-data item-data"
          // key="team_member"
        >
          {/* {info.team_member} */}
        </div>
        <div
          aria-hidden="true"
          onClick={() => {
            this.props.showModalConnect(DASHBOARD_CUSTOMER_MODAL, {
              id_object: {
                main_id: info.customer_id,
                other_id: info.packet_history_id,
                share_id: info.packet_share_id,
              },
              token,
              content: 'contactInfo',
            })
          }}
          className="dashboard-table-row-cell dashboard-table-cell-default item-data clickable"
        >
          {info.customer_name}
        </div>
        <div className="dashboard-table-row-cell dashboard-table-cell-default item-data">
          <Link to={`/packet/view/${info.vehicle_id}`}>{info.stock_number}</Link>
        </div>
        <div
          className="dashboard-table-row-cell dashboard-table-cell-default item-data"
          style={{ lineHeight: '19px', textAlign: 'left', paddingLeft: '7px' }}
        >
          <div
            className={`dashboard-table-sold-icon ${info.vehicle_status_id == 3 ? '' : 'hidden'}`}
          >
            <SoldIcon />
          </div>
          <Link to={`/packet/view/${info.vehicle_id}`}>
            <span>{`${info.vehicle_year} ${info.vehicle_make}`}</span>
            <br />
            <span style={{ paddingLeft: '7px' }}>{`${info.vehicle_model}`}</span>
          </Link>
        </div>
        <div
          className="dashboard-table-row-cell dashboard-table-cell-default item-data dashboard-table-cell-multi-row"
          key="sent_date"
        >
          <span style={{ fontSize: '0.9em' }}>
            {`${format(Helper.localizeTime(info.sent_date), 'M/d/yyyy')}`}
          </span>
          <br />
          <span style={{ fontSize: '0.95em' }}>
            {`${format(Helper.localizeTime(info.sent_date), 'h:mm a')}`}
          </span>
        </div>
        <div
          className="dashboard-table-row-cell dashboard-table-cell-default item-data"
          style={info.last_viewed_date && { display: 'flex', paddingLeft: '6px' }}
        >
          {info.last_viewed_date && (
            <span className="last-viewed-timestamp-cont">
              <span className="date">
                {`${format(Helper.localizeTime(info.last_viewed_date), 'M/d/yyyy')}`}
              </span>
              <br />
              <span className="time">
                {`${format(Helper.localizeTime(info.last_viewed_date), 'h:mm a')}`}
              </span>
            </span>
          )}
          <div
            aria-hidden="true"
            className={`${
              info.view_count
                ? 'dashboard-table-view-count clickable'
                : 'dashboard-table-view-count-zero'
            }`}
            onClick={() => {
              this.props.showModalConnect(DASHBOARD_VIEWS_MODAL, {
                id_object: { main_id: info.packet_share_id, other_id: null },
                token,
              })
            }}
          >
            {info.view_count}
          </div>
        </div>
        <div
          aria-hidden="true"
          onClick={() => this.openPacketTypeModal(info, share_uuid)}
          className={`dashboard-table-row-cell dashboard-table-cell-default item-data ${
            info.packet_type_label === 'webapp-text-message' ||
            info.packet_type_label === 'webapp-email' ||
            info.packet_type_label === 'packet-share-mms-app' ||
            info.packet_type_label === 'packet-share-mms' ||
            info.packet_type_label === 'mobile-app-email' ||
            info.packet_type_label === 'packet-share-email-app' ||
            info.packet_type_label === 'mobile-app-text-message' ||
            info.packet_type_label === 'packet-share-mms-app' ||
            info.packet_type_label === 'packet-link-only' ||
            info.packet_type_label === 'packet-share-link'
              ? ' clickable'
              : ''
          }`}
        >
          <span className="packet-type" style={{ fontSize: '0.8em' }}>
            {info.packet_type_label === 'packet-link-only'
              ? info.packet_type_client_display_label
              : info.packet_type_client_display_label}
            {info.covideo_video_id !== null ? <CovideoIcon /> : null}
          </span>
        </div>
        {hasInsights && (
          <div className="dashboard-table-row-cell dashboard-table-cell-default item-data dashboard-table-insights">
            <a
              className={`${info.heatmap ? '' : 'hidden'}`}
              target="_blank"
              href={`https://replay.autoipacket.com/insight/${info.packet_share_id}`}
            >
              <HeatmapIcon />
            </a>
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  token: state.userSettings.settings && state.userSettings.settings.dj_token,
})
export default connect(mapStateToProps, {
  showModalConnect: showModal,
})(DashboardRow)
