// core
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

// components
import Collapsible from 'react-collapsible'
import LoadingSpinner from '../../helpers/loadingspinner'
import { TabContent } from './formcomponents'
import PasswordForm from './passwordform'
import IconDot from '../../../core/assets/images/icons/icon-dot'
import InfoIcon from '../../../core/assets/images/icons/info-icon'

// Helpers
import {
  initialAccountSettingsState,
  initialErrors,
  initialModifiedFields,
  handleDataFetch,
  handleAccountSettingsUpdate,
  handleAccountSettingsFormErrors,
  handleModifiedValues,
  handleTabSectionEditIcon,
  updateUserWrapper,
  parseStoreTypes,
  fetchProfile,
  fetchPrivacyPreferences,
  isEmptyObject,
  areEmptyValues,
  saveProfile,
  savePrivacy,
  formatPhoneNumber,
} from './accountSettingsHelpers'

// actions
import { updateSettings } from '../../../core/_actions/settingsActions'
import { clearFacetOptions } from '../../../core/actions/inventoryactions'

// css styles
import '../styles/accountsettingsform.scss'
import '../styles/sectionselector.scss'

function AccountSetingsForm(props) {
  const { token, user_id, pref_id, updateUserSettings, clearFacetOptionsConnect } = props

  const [accountSettings, setAccountSettings] = useState(initialAccountSettingsState)
  const [formErrors, setFormErrors] = useState(initialErrors)
  const [modifiedFields, setModifiedFields] = useState(initialModifiedFields)

  const { userProfile, initialValues } = accountSettings
  const headers = ['Alerts', 'Packet Messages', 'Privacy', 'Zoom', 'Accessibility', 'Packet Send']

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('Alerts')
  const [canEditEmail, setCanEditEmail] = useState(false)
  const [formSubmitToggle, setFormSubmitToggle] = useState(false)
  const zoomRequestError =
    !userProfile.enable_zoom_request_email_notification &&
    !userProfile.enable_zoom_request_sms_notification
  const isAnyFieldModified =
    !isEmptyObject(modifiedFields.profile) || !isEmptyObject(modifiedFields.privacyPreferences)

  const getProfile = async () => {
    const fetchedProfile = await fetchProfile(user_id, token)
    if (!isEmptyObject(fetchedProfile)) {
      setAccountSettings(currentState => handleDataFetch(fetchedProfile, currentState))
      updateUserSettings(fetchedProfile)
      updateUserWrapper(fetchedProfile)
    } else {
      toast('Error: unable to load profile')
    }
  }

  const getPrivacyPreferences = async () => {
    const fetchedPrivacyPreferences = await fetchPrivacyPreferences(token, pref_id)
    if (!isEmptyObject(fetchedPrivacyPreferences)) {
      setAccountSettings(currentState => handleDataFetch(fetchedPrivacyPreferences, currentState))
      clearFacetOptionsConnect()
    } else {
      toast('Error: unable to load profile')
    }
  }

  const handleFormChanges = (e, value, rteGroup) => {
    const group = typeof e === 'object' ? e.target.dataset.group : rteGroup
    let id = e
    if (typeof e === 'object') id = e.target.id
    if (typeof e === 'object' && e.target.name === 'view_frequency') id = e.target.name
    if (typeof e === 'object' && e.target.name === 'emailMessageIsCustom') id = e.target.name
    if (typeof e === 'object' && e.target.name === 'emailSignatureIsCustom') id = e.target.name

    setAccountSettings(currentState => handleAccountSettingsUpdate(id, value, currentState))
    setFormErrors(currentState => handleAccountSettingsFormErrors(id, value, currentState))
    setModifiedFields(currentState =>
      handleModifiedValues(id, group, value, initialValues, currentState),
    )
  }

  const handleFormSubmit = async () => {
    setLoading(true)
    const currentProfile = userProfile
    const modifiedProfile = { ...modifiedFields.profile }

    // Array of alert items to check upon alerts updates
    const properties = ['send_mobile_alerts', 'send_email_alerts', 'view_frequency']
    properties.forEach(property => {
      // Check if the property has been modified
      if (!modifiedProfile.hasOwnProperty(property)) {
        // Update the property with its initial value if unchanged
        modifiedProfile[property] = initialValues[property]
      }
    })

    const savedProfileResponse = await saveProfile(user_id, token, modifiedProfile, currentProfile)
    if ('first_name' in savedProfileResponse) {
      delete savedProfileResponse.is_admin // remove the is_admin value (not the same as onAppLoad in login)
      updateUserWrapper(savedProfileResponse)
      updateUserSettings(savedProfileResponse)
      if (savedProfileResponse.default_store_id) clearFacetOptionsConnect()
      resetForm()
    }
    if ('non_field_errors' in savedProfileResponse) {
      toast(savedProfileResponse.non_field_errors[0])
    }

    const savedPrivacyResponse = await savePrivacy(
      token,
      pref_id,
      modifiedFields.privacyPreferences,
    )
    if ('first_name' in savedProfileResponse || 'cvd_mode' in savedPrivacyResponse)
      toast('Saved changes to profile')
    if ('cvd_mode' in savedPrivacyResponse) window.location.reload()

    // if ('error' in savedProfileResponse) toast('Error: unable to save profile')
    // if ('error' in savedPrivacyResponse) toast('Error: unable to save profile')

    if (isEmptyObject(savedProfileResponse) && isEmptyObject(savedPrivacyResponse)) {
      toast('No changes made to profile')
    }

    setLoading(false)
  }

  const resetEmailField = () => {
    setCanEditEmail(false)
    setAccountSettings(currentState => {
      return {
        ...currentState,
        ['userProfile']: {
          ...currentState.userProfile,
          ['user_email_address']: currentState.initialValues.user_email_address,
        },
      }
    })
    if ('user_email_address' in modifiedFields.profile)
      setModifiedFields(currentState => {
        let updatedState = { ...currentState }
        delete updatedState['profile']['user_email_address']
        return updatedState
      })
    setFormErrors(currentState => {
      return {
        ...currentState,
        user_email_address: '',
      }
    })
  }

  const resetForm = () => {
    setFormSubmitToggle(!formSubmitToggle)
    setCanEditEmail(false)
    setModifiedFields(currentState => {
      return {
        ...currentState,
        profile: {},
        privacyPreferences: {},
      }
    })
  }

  const passwordChangeToggle = useRef(null)

  useEffect(() => {
    setLoading(true)
    getProfile()
    getPrivacyPreferences()
    setLoading(false)
  }, [formSubmitToggle])

  if (loading) {
    return <LoadingSpinner loading wheel />
  }

  return (
    <div className="form-section-wrapper">
      <h2>Account Settings</h2>
      {/*
        <div className="form-row">
          <Collapsible
            trigger={
              <label ref={passwordChangeToggle} id="changePass-btn">
                Change Password
              </label>
            }
            transitionTime={150}
          >
            <PasswordForm
              token={token}
              closeForm={() => {
                passwordChangeToggle.current.click()
              }}
            />
          </Collapsible>
        </div>
      */}
      <div className="form-row">
        <div className="account-settings-banner">
          <div className="account-settings-banner-icon">
            <InfoIcon />
          </div>
          <h1>
            Account Management
            <br />
            Has Been Updated!
          </h1>
          <h2>
            Manage your <b>password</b>, <b>mobile number</b>, and{' '}
            <b>multi factor authentication (MFA)</b> at our new iPacket Account Site.
          </h2>
          <a
            className="account-settings-banner-button"
            target="_blank"
            href={
              window.location.hostname === 'dpapp.autoipacket.com'
                ? 'https://auth.autoipacket.com/account'
                : 'https://auth.staging-ipacket.us/account'
            }
          >
            Manage Account Settings
          </a>
        </div>
      </div>
      <div className="form-row">
        <div className="form-container">
          <label htmlFor="first_name">
            First Name
            {Object.hasOwn(modifiedFields.profile, 'first_name') && <IconDot />}
          </label>
          <input
            type="text"
            name="profile"
            id="first_name"
            value={userProfile.first_name}
            onChange={e => handleFormChanges(e, e.target.value)}
            placeholder="First"
            data-group="profile"
          />
          {formErrors.first_name && <div className="error">{formErrors.first_name}</div>}
        </div>
        <div className="form-container">
          <label htmlFor="last_name">
            Last Name
            {Object.hasOwn(modifiedFields.profile, 'last_name') && <IconDot />}
          </label>
          <input
            type="text"
            name="profile"
            id="last_name"
            value={userProfile.last_name}
            onChange={e => handleFormChanges(e, e.target.value)}
            placeholder="Last"
            data-group="profile"
          />
          {formErrors.last_name && <div className="error">{formErrors.last_name}</div>}
        </div>
      </div>
      {/*
        <div className="form-email">
          <div className="form-container">
            <label htmlFor="user_email_address">
              Email Address
              {Object.hasOwn(modifiedFields.profile, 'user_email_address') && <IconDot />}
            </label>
            <span className="input-with-button">
              <input
                type="email"
                name="profile"
                className="text-input"
                id="user_email_address"
                value={userProfile.user_email_address}
                onChange={e => handleFormChanges(e, e.target.value)}
                disabled={!canEditEmail}
                data-group="profile"
              />
              {!canEditEmail ? (
                <button type="button" onClick={() => setCanEditEmail(true)}>
                  Edit
                </button>
              ) : (
                <button type="button" onClick={() => resetEmailField()}>
                  Cancel
                </button>
              )}
            </span>
            {canEditEmail ? (
              <div className="error">Updating your email will also update your login username!</div>
            ) : null}
            {canEditEmail
              ? formErrors.user_email_address && (
                  <div className="error">{formErrors.user_email_address}</div>
                )
              : null}
          </div>
        </div>
      */}

      <div className="form-row">
        <div className="form-container">
          <label htmlFor="user_phone">
            Main Phone
            {Object.hasOwn(modifiedFields.profile, 'user_phone') && <IconDot />}
          </label>
          <input
            type="tel"
            name="profile"
            id="user_phone"
            value={
              userProfile.user_phone && userProfile.user_phone.length === 10
                ? formatPhoneNumber(userProfile.user_phone)
                : userProfile.user_phone
            }
            onChange={e => handleFormChanges(e, e.target.value.replace(/\D/g, ''))}
            placeholder="Primary"
            maxLength="10"
            data-group="profile"
          />
          {formErrors.user_phone && <div className="error">{formErrors.user_phone}</div>}
        </div>

        <div className="form-container">
          <label htmlFor="user_mobile">
            Mobile Phone
            {/*Object.hasOwn(modifiedFields.profile, 'user_mobile') && <IconDot />*/}
          </label>
          <a
            className="form-button"
            target="_blank"
            href={
              window.location.hostname === 'dpapp.autoipacket.com'
                ? 'https://auth.autoipacket.com/account'
                : 'https://auth.staging-ipacket.us/account'
            }
          >
            Update Number{' '}
          </a>
          {/*
          <input
            type="tel"
            name="profile"
            id="user_mobile"
            value={
              userProfile.user_mobile && userProfile.user_mobile.length === 10
                ? formatPhoneNumber(userProfile.user_mobile)
                : userProfile.user_mobile
            }
            onChange={e => handleFormChanges(e, e.target.value.replace(/\D/g, ''))}
            placeholder="Mobile"
            maxLength="10"
            data-group="profile"
          />
          {formErrors.user_mobile && <div className="error">{formErrors.user_mobile}</div>}
          <div style={{ fontSize: '0.7em', lineHeight: '1.1em', marginTop: '3px' }}>
            *By entering your phone number, you consent to receive text message notifications from
            AutoiPacket, LLC. Message and data rates may apply.
          </div>
          */}
        </div>
      </div>
      <div className="form-row">
        <div className="form-container">
          <label htmlFor="default_store_id">
            Default Store
            {Object.hasOwn(modifiedFields.profile, 'default_store_id') && <IconDot />}
          </label>
          <select
            name="profile"
            id="default_store_id"
            onChange={e => handleFormChanges(e, e.target.value)}
            data-group="profile"
          >
            {userProfile.store_groups &&
              userProfile.store_groups.length > 0 &&
              userProfile.store_groups.map(storeObj => (
                <option
                  key={storeObj.company_store_id}
                  value={storeObj.company_store_id}
                  data-storetype={storeObj.company_store_id}
                  selected={storeObj.company_store_id === userProfile.default_store_id}
                >
                  {`${storeObj.company_store_name} ${parseStoreTypes(storeObj.store_type)}`}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="button-list">
        {headers.map((header, i) => {
          return (
            <button
              className={
                currentTab === header
                  ? 'form-selector-button form-selector-button--selected'
                  : 'form-selector-button'
              }
              onClick={() => setCurrentTab(header)}
              key={i}
            >
              {header}
              {handleTabSectionEditIcon(header, modifiedFields) && <IconDot />}
            </button>
          )
        })}
      </div>
      <TabContent
        selectedTab={currentTab}
        handleFormChanges={handleFormChanges}
        userProfile={userProfile}
        errors={formErrors}
        zoomRequestError={zoomRequestError}
      />
      <div className="form-container">
        <label htmlFor="crm_email">
          CRM Email Field
          {Object.hasOwn(modifiedFields.profile, 'crm_email') && <IconDot />}
        </label>
        <input
          type="text"
          name="profile"
          id="crm_email"
          value={userProfile.crm_email}
          onChange={e => handleFormChanges(e, e.target.value)}
          placeholder="CRM Email"
          data-group="profile"
        />
        {formErrors.crm_email && <div className="error">{formErrors.crm_email}</div>}
      </div>
      <button
        type="submit"
        className="form-submission-button"
        disabled={!isAnyFieldModified || areEmptyValues(formErrors) || zoomRequestError}
        onClick={() => handleFormSubmit()}
      >
        Save
      </button>
    </div>
  )
}

const mapStateToProps = state => ({
  accountSettings: state.accountSettings,
  token: state.authentication.user.token,
  user_id: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  pref_id: state.authentication.user.user.profile.profile_prefs[0],
})

const mapDispatchToProps = dispatch => ({
  updateUserSettings: userSettings => dispatch(updateSettings(userSettings)),
  clearFacetOptionsConnect: () => dispatch(clearFacetOptions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetingsForm)
